import React, { useRef, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ProjectItemHeader from './ProjectItemHeader';
import ProjectItemDetail from './ProjectItemDetail';

const ProjectItem = ({ 
    index, 
    style, 
    item, 
    mergeTarget, 
    setMergeTarget, 
    mergeItem, 
    hideItem, 
    deleteItem, 
    autocompleteRef, 
    isAdmin, 
    data,
    sizeMap,
    listRef,
    sortBy
}) => {
    const itemRef = useRef(null);
    const [shouldUpdateSize, setShouldUpdateSize] = useState(true);

    useEffect(() => {
        if (itemRef.current && shouldUpdateSize) {
            const height = itemRef.current.getBoundingClientRect().height;
            if (height !== sizeMap.current[index]) {
                sizeMap.current[index] = height;
                listRef.current.resetAfterIndex(index);
            }
            setShouldUpdateSize(false);
        }
    }, [shouldUpdateSize, index, sizeMap, listRef]);

    const updateSize = () => {
        setShouldUpdateSize(true);
    };

    return (
        <div ref={itemRef} style={{ ...style, height: 'auto',  }}>
            <Box sx={{
                display: 'flex',
                maxWidth:'800px',
                flexDirection: 'column',
                alignItems: 'stretch',
                background:'#363533',
                borderRadius:'10px',
                border:"1px solid #3f3f3f",
                overflow:'hidden',
                margin: '7px auto',
                padding: 0
            }}>
                <ProjectItemHeader
                    name={item.name}
                    score={item[sortBy]}
                    mergeTarget={mergeTarget}
                    setMergeTarget={setMergeTarget}
                    mergeItem={mergeItem}
                    hideItem={hideItem}
                    deleteItem={deleteItem}
                    autocompleteRef={autocompleteRef}
                    isAdmin={isAdmin}
                    data={data}
                />
                <ProjectItemDetail
                    project={item}
                    updateSize={updateSize}
                />
            </Box>
        </div>
    );
};

export default ProjectItem;